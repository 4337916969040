<template>
  <b-card>

    <!-- form -->
    <b-form
      class="mt-2"
    >
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Notifications"
            label-for="notifications"
          >
            <b-form-checkbox
              v-model="optionsLocal.notifications"
              name="notifications"
              switch
              @change="submitForm"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="isSupplier"
      >
        <b-col sm="6">
          <b-form-group
            label="Clients"
            label-for="notification-clients"
          >
            <v-select
              v-model="optionsLocal.notification_clients"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clientOptions"
              :reduce="val => val.value"
              :clearable="true"
              :multiple="true"
              :disabled="!optionsLocal.notifications"
              input-id="notification-clients"
              @input="submitForm"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Style Draft"
            label-for="style-draft"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_status_draft_in_app"
              name="style-draft-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Published"
            label-for="style-draft"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_status_published_in_app"
              name="style-published-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Ready to Sample"
            label-for="style-ready-to-sample"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_status_ready_to_sample_in_app"
              name="style-ready-to-sample-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Archived"
            label-for="style-archived"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_status_archived_in_app"
              name="style-archived-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Sizes and Measurement Commented"
            label-for="style-sizes-and-measurements-commented-in-app"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_sizes_and_measurements_commented_in_app"
              name="style-sizes-and-measurements-commented-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Prices Commented"
            label-for="style-prices-commented-in-app"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_prices_commented_in_app"
              name="style-prices-commented-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Planned"
            label-for="sample-planned"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_planned_in_app"
              name="sample-planned-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Requested"
            label-for="sample-requested"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_requested_in_app"
              name="sample-requested-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Confirmed"
            label-for="sample-confirmed"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_confirmed_in_app"
              name="sample-confirmed-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>

          <b-form-group
            label="Sample Ready to make"
            label-for="sample-ready-to-make"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_ready_to_make_in_app"
              name="sample-ready-to-make-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Sent"
            label-for="sample-sent"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_sent_in_app"
              name="sample-sent-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Received"
            label-for="sample-received"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_received_in_app"
              name="sample-received-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Cancelled"
            label-for="sample-cancelled"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_cancelled_in_app"
              name="sample-cancelled-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Commented"
            label-for="sample-commented"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_commented_in_app"
              name="sample-commented-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Material Commented"
            label-for="material-commented"
          >
            <b-form-checkbox
              v-model="optionsLocal.material_commented_in_app"
              name="material-commented-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Embellishment Commented"
            label-for="embellishment-commented"
          >
            <b-form-checkbox
              v-model="optionsLocal.embellishment_commented_in_app"
              name="material-embellishment-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Not Sent"
            label-for="sample-not-sent-in-app"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_not_sent_in_app"
              name="sample-not-sent-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Not Received"
            label-for="sample-not-received-in-app"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_not_received_in_app"
              name="sample-not-received-in-app"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Style Draft Email"
            label-for="style-draft-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_status_draft_email"
              name="style-draft-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Published Email"
            label-for="style-draft-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_status_published_email"
              name="style-published-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Ready to Sample Email"
            label-for="style-ready-to-sample-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_status_ready_to_sample_email"
              name="style-ready-to-sample-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Archived Email"
            label-for="style-archived-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_status_archived_email"
              name="style-archived-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Sizes and Measurement Commented Email"
            label-for="style-sizes-and-measurements-commented-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_sizes_and_measurements_commented_email"
              name="style-sizes-and-measurements-commented-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Style Prices Commented Email"
            label-for="style-prices-commented-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.style_prices_commented_email"
              name="style-prices-commented-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Planned Email"
            label-for="sample-planned-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_planned_email"
              name="sample-planned-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Requested Email"
            label-for="sample-requested-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_requested_email"
              name="sample-requested-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Confirmed Email"
            label-for="sample-confirmed-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_confirmed_email"
              name="sample-confirmed-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>

          <b-form-group
            label="Sample Ready to make Email"
            label-for="sample-ready-to-make-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_ready_to_make_email"
              name="sample-ready-to-make-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Sent Email"
            label-for="sample-sent-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_sent_email"
              name="sample-sent-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Received Email"
            label-for="sample-received-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_received_email"
              name="sample-received-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Cancelled Email"
            label-for="sample-cancelled-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_cancelled_email"
              name="sample-cancelled-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Commented Email"
            label-for="sample-commented-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_commented_email"
              name="sample-commented-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Material Commented Email"
            label-for="material-commented-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.material_commented_email"
              name="material-commented-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Embellishment Commented Email"
            label-for="embellishment-commented-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.embellishment_commented_email"
              name="material-embellishment-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Not Sent Email"
            label-for="sample-not-sent-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_not_sent_email"
              name="sample-not-sent-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
          <b-form-group
            label="Sample Not Received Email"
            label-for="sample-not-received-email"
          >
            <b-form-checkbox
              v-model="optionsLocal.sample_not_received_email"
              name="sample-not-received-email"
              switch
              :disabled="!optionsLocal.notifications"
              @change="submitForm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BForm, BFormCheckbox, BFormGroup, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormCheckbox,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,

    vSelect,
  },
  props: {
    notificationsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    return {
      isSupplier: userRole === 'supplier' || userRole === 'supplier-admin',
      optionsLocal: JSON.parse(JSON.stringify(this.notificationsData)),
      clientOptions: [],
    }
  },
  created() {
    this.fetchClients()
  },
  methods: {
    fetchClients() {
      this.$http.get('/api/v1/clients/')
        .then(response => {
          this.clientOptions = response.data.map(client => ({ label: client.name, value: client.name }))
        })
    },
    submitForm() {
      const submitData = {
        ...this.optionsLocal,
        notification_clients: this.optionsLocal.notification_clients.map(val => ({ name: val })),
      }
      this.$http.patch('/api/v1/users/me/', submitData).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Settings saved',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
svg {
  fill: white;
}
</style>
