<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Information</span>
      </template>

      <account-setting-information
        v-if="options.info"
        :information-data="options.info"
      />
    </b-tab>

    <!-- notifications -->
    <b-tab>

      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notifications
        v-if="options.info"
        :notifications-data="options.notifications"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingNotifications from './AccountSettingNotifications.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingNotifications,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    this.$http.get('/api/v1/users/me/').then(response => {
      if (response.data) {
        const {
          email,
          first_name: firstName,
          last_name: lastName,
          photo,
          job_description,
          notifications,
          style_status_draft_in_app,
          style_status_published_in_app,
          style_status_ready_to_sample_in_app,
          style_status_archived_in_app,
          style_sizes_and_measurements_commented_in_app,
          style_prices_commented_in_app,
          sample_planned_in_app,
          sample_requested_in_app,
          sample_confirmed_in_app,
          sample_ready_to_make_in_app,
          sample_sent_in_app,
          sample_received_in_app,
          sample_cancelled_in_app,
          sample_commented_in_app,
          material_commented_in_app,
          embellishment_commented_in_app,
          sample_not_sent_in_app,
          sample_not_received_in_app,
          style_status_draft_email,
          style_status_published_email,
          style_status_ready_to_sample_email,
          style_status_archived_email,
          style_sizes_and_measurements_commented_email,
          style_prices_commented_email,
          sample_planned_email,
          sample_requested_email,
          sample_confirmed_email,
          sample_ready_to_make_email,
          sample_sent_email,
          sample_received_email,
          sample_cancelled_email,
          sample_commented_email,
          material_commented_email,
          embellishment_commented_email,
          sample_not_sent_email,
          sample_not_received_email,
          notification_clients,
        } = response.data
        this.options = {
          general: {
            photo,
            firstName,
            lastName,
            email,
          },
          info: {
            job_description,
          },
          notifications: {
            notifications,
            style_status_draft_in_app,
            style_status_published_in_app,
            style_status_ready_to_sample_in_app,
            style_status_archived_in_app,
            style_sizes_and_measurements_commented_in_app,
            style_prices_commented_in_app,
            sample_planned_in_app,
            sample_requested_in_app,
            sample_confirmed_in_app,
            sample_ready_to_make_in_app,
            sample_sent_in_app,
            sample_received_in_app,
            sample_cancelled_in_app,
            sample_commented_in_app,
            material_commented_in_app,
            embellishment_commented_in_app,
            sample_not_sent_in_app,
            sample_not_received_in_app,
            style_status_draft_email,
            style_status_published_email,
            style_status_ready_to_sample_email,
            style_status_archived_email,
            style_sizes_and_measurements_commented_email,
            style_prices_commented_email,
            sample_planned_email,
            sample_requested_email,
            sample_confirmed_email,
            sample_ready_to_make_email,
            sample_sent_email,
            sample_received_email,
            sample_cancelled_email,
            sample_commented_email,
            material_commented_email,
            embellishment_commented_email,
            sample_not_sent_email,
            sample_not_received_email,
            notification_clients: notification_clients.map(client => client.name),
          },
        }
      }
    })
  },
}
</script>
