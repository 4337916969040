<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.photo"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="photoFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageUpload"
        />
        <!--/ upload button -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form
      class="mt-2"
      @submit.prevent="submitForm"
    >
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="First name"
            label-for="account-first-name"
          >
            <b-form-input
              v-model="optionsLocal.firstName"
              name="firstName"
              placeholder="First name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Last name"
            label-for="account-last-name"
          >
            <b-form-input
              v-model="optionsLocal.lastName"
              name="lastName"
              placeholder="Last name"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            type="submit"
            @click.prevent="submitForm"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      photoFile: null,
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    clearFile() {
      this.$refs.refInputEl.reset()
    },
    inputImageUpload(inputEl) {
      const formData = new FormData()

      formData.append('photo', inputEl)
      this.$http.patch('/api/v1/users/me/', formData)
        .then(response => {
          this.inputImageRenderer(inputEl)
          this.$store.commit('navBar/UPDATE_PHOTO', response.data.photo)
        })
    },
    submitForm() {
      const { firstName, lastName } = this.optionsLocal
      const submitData = { first_name: firstName, last_name: lastName }
      this.$http.patch('/api/v1/users/me/', submitData).then(response => {
        const {
          first_name: firstNameUpdated,
          last_name: lastNameUpdated,
        } = response.data
        this.$store.commit('navBar/UPDATE_FIRST_NAME', firstNameUpdated)
        this.$store.commit('navBar/UPDATE_LAST_NAME', lastNameUpdated)
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewImage => {
      if (previewImage) {
        previewEl.value.src = previewImage
      }
    })
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
