<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- Job description -->
        <b-col md="6">
          <b-form-group
            label-for="job-description"
            label="Job role"
          >
            <b-form-input
              id="job-description"
              v-model="localOptions.job_description"
              placeholder="Job role"
            />
          </b-form-group>
        </b-col>
        <!--/ Job description -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="submitForm"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
    }
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
    submitForm() {
      const submitData = { ...this.localOptions }
      this.$http.patch('/api/v1/users/me/', submitData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Information saved',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
